import React from 'react';

/*---CVT---*/
import { Button } from '@conventic-web/internal.cvt.basic.button';
import {
  CvtColorSchema,
  DeviceSizes,
  CvtBasicTheme,
} from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { useWindowDimensions } from '@conventic-web/internal.utility';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';

/*---Components---*/
import { LinkComponentExtern } from '../../components/linkComponent';

/*---Global---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const createTileRight = (data, width) => {
  return (
    <div>
      <div>
        <a
          href={data[0].link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '0px' }}
        >
          <Button
            bgColor={CvtColorSchema.yellow}
            color={CvtColorSchema.fontDarkGray}
            text={data[0].btnContent}
            hoverColor={CvtColorSchema.green}
            name={'Standardbutton'}
            type={'button'}
            disabled={false}
          />
        </a>
        <Headline variant="h2" text={data[0].headline} />
        <Headline text={data[0].subheadline} variant={'h3'} />
        <HtmlContent
          dangerouslySetInnerHTML={md.render(data[0].content)}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponentExtern linkTo={data[0].link}>
          <img
            height={width > 768 ? '80px' : '40px'}
            width="auto"
            src={data[0].image.publicURL}
            alt={data[0].alt}
          />
        </LinkComponentExtern>
      </div>
      <div>
        <div>
          <a
            href={data[1].link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '0px' }}
          >
            <Button
              bgColor={CvtColorSchema.yellow}
              color={CvtColorSchema.fontDarkGray}
              text={data[1].btnContent}
              hoverColor={CvtColorSchema.green}
              name={'Standardbutton'}
              type={'button'}
              disabled={false}
            />
          </a>
          <Headline variant="h2" text={data[1].headline} />
          <Headline text={data[1].subheadline} variant={'h3'} />
          <HtmlContent
            dangerouslySetInnerHTML={md.render(data[1].content)}
            style={CvtBasicTheme.htmlContent.general}
          />
          <LinkComponentExtern linkTo={data[1].link}>
            <img
              height={width > 768 ? '80px' : '40px'}
              width="auto"
              src={data[1].image.publicURL}
              alt={data[1].alt}
            />
          </LinkComponentExtern>
        </div>
      </div>
    </div>
  );
};

const createTileLeft = (data, width) => {
  return (
    <div>
      <div>
        <a
          href={data[0].link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '0px' }}
        >
          <Button
            bgColor={CvtColorSchema.yellow}
            color={CvtColorSchema.fontDarkGray}
            text={data[0].btnContent}
            hoverColor={CvtColorSchema.green}
            name={'Standardbutton'}
            type={'button'}
            disabled={false}
          />
        </a>
        <Headline variant="h2" text={data[0].headline} />
        <Headline text={data[0].subheadline} variant={'h3'} />
        <HtmlContent
          dangerouslySetInnerHTML={md.render(data[0].content)}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponentExtern linkTo={data[0].link}>
          <img
            height={width > 768 ? '80px' : '40px'}
            width="auto"
            src={data[0].image.publicURL}
            alt={data[0].alt}
          />
        </LinkComponentExtern>
      </div>
      <div>
        <a
          href={data[1].link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '0px' }}
        >
          <Button
            bgColor={CvtColorSchema.yellow}
            color={CvtColorSchema.fontDarkGray}
            text={data[1].btnContent}
            hoverColor={CvtColorSchema.green}
            name={'Standardbutton'}
            type={'button'}
            disabled={false}
          />
        </a>

        <Headline variant="h2" text={data[1].headline} />
        <Headline text={data[1].subheadline} variant={'h3'} />
        <HtmlContent
          dangerouslySetInnerHTML={md.render(data[1].content)}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponentExtern linkTo={data[1].link}>
          <img
            height={width > 768 ? '80px' : '40px'}
            width="auto"
            src={data[1].image.publicURL}
            alt={data[1].alt}
          />
        </LinkComponentExtern>
      </div>
    </div>
  );
};

const CardAlliance = ({ data, zIndex }: any) => {
  const { width } = useWindowDimensions();
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerTiles: {
      display: 'flex',
      flexDirection: 'column',
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        flexDirection: 'row',
      },
    },
    pad: {
      padding: '8em 8% 8em 8%',
    },
    darkTileRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: CvtColorSchema.bgDarkGray,
      color: CvtColorSchema.white,
      clipPath: 'none',
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        clipPath: 'polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%)',
      },
    },
    limitTextWidth: {
      width: '90%',
      textAlign: 'right',
    },
    darkTileLeft: {
      backgroundColor: CvtColorSchema.bgDarkGray,
      color: CvtColorSchema.white,
      clipPath: 'none',
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        clipPath: 'polygon(0 0%,100% 0%,70% 100%,0% 100%)',
      },
    },
  } as const;
  let filterArr = [];

  //Filter to colorize specific parts - every 2 and 3 Tile
  for (let i = 0; i < data.length; i = i + 4) {
    filterArr.push(i);
    filterArr.push(i + 1);
  }

  let retVal = [];
  for (let i = 0; i < data.length; i = i + 2) {
    retVal.push(
      <React.Fragment key={i}>
        <BlockStandard
          bgColor={CvtColorSchema.bgGray}
          padding={PaddingObj.PaddingNone}
          diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
          zIndex={zIndex}
          color={CvtColorSchema.fontDarkGray}
        >
          <div>
            {filterArr.includes(i)
              ? createTileRight(data.slice(i, i + 2), width)
              : createTileLeft(data.slice(i, i + 2), width)}
          </div>
        </BlockStandard>
      </React.Fragment>
    );
    zIndex--;
  }
  return retVal;
};

/**
 * @description Provides the Conventic-ColorSchema.
 */

export default CardAlliance;
